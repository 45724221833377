import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';

const Message = ({message}) => {

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext); 

  const ref = useRef();
  
  useEffect(() => {
    ref.current?.scrollIntoView({behavior:"smooth"}) 
  }, [message]); // make scrolling smooth
  
  return (
    <div ref={ref}
    className={`message ${message.senderId === currentUser.uid && "owner"}`}> {/*Get the sender's profile picture, if any, else the default one.*/} 
      <div className="messageInfo">
        <img 
          src={
            message.senderId === currentUser.uid 
            ? currentUser.photoURL 
            : data.user.photoURL
          } // User photo
          alt="" 
        />
        <span></span>
      </div>
      <div className="messageContent">
        <p>{message.text}</p>
        {message.img && <img src={message.img} alt="" />}  {/*If there is an image the user wants to send, save it*/}
      </div> 
    </div>
  );
};

export default Message;
